import React from "react";
import styled from "styled-components";

import { PageTemplate } from "../templates/pageTemplate";
import { Seo } from "../components/Seo";

const WdnCommitteeStyled = styled.section``;

const WdnCommittee = () => {
  return (
    <>
      <Seo title="WDN | Nominating Committee" />
      <PageTemplate>
        <WdnCommitteeStyled>
          <h2>WdnCommittee</h2>
          <p>
            This is a project to help people find the best place to eat in the
            city.
          </p>
        </WdnCommitteeStyled>
      </PageTemplate>
    </>
  );
};

export default WdnCommittee;
